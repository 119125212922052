import React, { useState, useEffect } from "react";

function Candle(props) {
  return (
    <React.Fragment>
      <div
        className={
          props.index % 2 !== 0
            ? "addcandleodd-temple row"
            : "addcandleeven-temple row"
        }
      >
        <div
          className="crossbutton-temple"
          onClick={(e) => {
            props.formValues.email
              ? console.log("")
              : props.setCandle({
                  candleIndex: props.index,
                  personIndex: props.personname,
                  value: null,
                });
          }}
        >
          <span
            class={
              !!props.formValues.email
                ? "material-icons temple-disabled"
                : "material-icons"
            }
          >
            highlight_off
          </span>
        </div>
        <div className="crossbutton-temple">
          <h3 className="heading-temple coninfo-temple col-10">
            Black&nbsp;Candle{props.index}
          </h3>
        </div>

        <div className="col-sm-5 candaledd-temple">
          <h3 className="points-temple">
            <strong>Candle</strong>
            <strong className="start-temple"> *</strong>
          </h3>
          <div class="dropdown">
            <select
              className="dropdown"
              class="btn-light dropdown-toggle"
              onChange={(e) => {
                props.setChangeCandle({
                  action: true,
                  value: e.target.value,
                  index: props.index,
                });
              }}
              type="button"
              data-toggle="dropdown"
              name={`person${props.personIndex + 1}blackcandle${props.index}`}
              value={
                props.formValues[
                  `person${props.personIndex + 1}blackcandle${props.index}`
                ] || props.candleitem.candleValue
              }
              ref={props.register({ required: true })}
              disabled={
                !!props.formValues[
                  `person${props.personIndex + 1}blackcandle${props.index}`
                ]
              }
            >
              <option value=""></option>
              <option value="Banish Negative Effects of Rahu">
                Banish Negative Effects of Rahu
              </option>
              <option value="Banish Negative Effects of Ketu">
                Banish Negative Effects of Ketu
              </option>
              <option value="Banish Negative Effects of Saturn">
                Banish Negative Effects of Saturn
              </option>
              <option value="Banish Politics and Backbiting">
                Banish Politics and Backbiting
              </option>
              <option value="Banish Addictions">Banish Addictions</option>
              <option value="Banish Debts and Loans">
                Banish Debts and Loans
              </option>
              <option value="Banish Nazar, Psychic Attack, Evil eye">
                Banish Nazar, Psychic Attack, Evil eye
              </option>
              <option value="Banish Negative Emotions">
                Banish Negative Emotions
              </option>
              <option value="Banish Negative Effects of Mercury Retrograde">
                Banish Negative Effects of Mercury Retrograde
              </option>
              <option value="Banish Negative Effects of Uranus Retrograde">
                Banish Negative Effects of Uranus Retrograde
              </option>
              <option value="Banish Negative Effects of Lunar Eclipse">
                Banish Negative Effects of Lunar Eclipse
              </option>
              <option value="Banish Negative Karma">
                Banish Negative Karma
              </option>
              <option value="Banish...">Banish...</option>
              <option value="Road Opening">Road Opening</option>
              <option value="Black Magic Removal">Black Magic Removal</option>
              <option value="Saturn Retrograde">Saturn Retrograde</option>
              <span class="caret"></span>
            </select>
            {props.errors[
              `person${props.personIndex + 1}blackcandle${props.index}`
            ] && <p className="error-temple">Field are required.</p>}
          </div>
          <br />
        </div>
        {props.candleitem.candleValue == "Banish..." ? (
          <div className="col-sm-5 candaledd-temple">
            <h3 className="points-temple">
              <strong>Banish List</strong>
              <strong className="start-temple"> *</strong>
            </h3>
            <input
              className="input-temple banishinput-temple"
              name={`person${props.personIndex + 1}blackcandle${
                props.index
              }query`}
              defaultValue={
                props.formValues[
                  `person${props.personIndex + 1}blackcandle${props.index}query`
                ] || ""
              }
              ref={props.register({ required: true })}
              disabled={
                !!props.formValues[
                  `person${props.personIndex + 1}blackcandle${props.index}query`
                ]
              }
            />
            {props.errors[
              `person${props.personIndex + 1}blackcandle${props.index}query`
            ] && <p className="error-temple">Field is required.</p>}

            <p6 className="in-temple inputline-temple">
              <em className="inputinfo-temple">
                Please enter not more than two items to banish
              </em>
            </p6>
          </div>
        ) : (
          ""
        )}
      </div>
    </React.Fragment>
  );
}

export default Candle;
