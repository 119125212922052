import React, { useState, useEffect } from "react";
import axios from "axios";
import "./tom.css";
import { urlParamParser, checkPaymentStatus, clearlocalStorage } from "../lib/helper";

export default () => {
  const queryMap = {};
  const [status, setStatus] = useState("STAGED");

  if (!checkPaymentStatus()) {
    const urlSearchParam = new URLSearchParams(window.location.search);

    for (const [key, value] of urlSearchParam.entries()) {
      if (key === "amount") {
        let amt = value.split(" ")[1] || value.split(" ")[0];
        // Removing any comma
        amt = amt.split(",").join("");
        queryMap[key] = amt;
      } else {
        queryMap[key] = value;
      }
    }
    if (Object.keys(queryMap).length > 1) {
      localStorage.setItem("_tom", JSON.stringify(queryMap));
    }
  }

  urlParamParser();

  const onPay = async (data) => {
    setStatus("INPROGRESS");

    try {
      const url = "https://us-central1-next-cms-a9a2b.cloudfunctions.net/bot/tom-payment";
      data.token = "xyz123";
      console.log(data);
      const res = await axios.post(url, data);
      localStorage.setItem("_preqid", res.data.id);
      //   window.history.pushState({}, document.title, res.data.url);
      localStorage.setItem("_retryurl", res.data.url);
      setTimeout(() => window.location.replace(res.data.url, 2000));
    } catch (err) {
      console.error(err);
      setStatus("RETRY");
    }
  };

  useEffect(() => {
    if (checkPaymentStatus() || queryMap.amount == 0) {
      clearlocalStorage();
      setStatus("Payment Done");
      alert("Submitted, Please check your email");
    } else {
      if (Object.keys(queryMap).length > 1) {
        onPay(queryMap);
      } else if (localStorage.getItem("_retryurl")) {
        setStatus("RETRY");
      } else {
        setStatus("ERROR");
      }
    }
  }, []);
  return (
    <React.Fragment>
      <h1>Temple Of Miracles Payment Gateway</h1>
      <div className="tomdetails">
        <h5>Status: {status}</h5>
        {status === "RETRY" && (
          <div className="retry">
            <button onClick={() => onPay(JSON.parse(localStorage.getItem("_tom")))}>Retry</button>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};
