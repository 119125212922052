import React, { useState, useEffect } from "react";

function DatePicker(props) {
  return (
    <React.Fragment>
      <fieldset className="fieldset-temple">
        <h3 className="socialmedia-temple">
          Did you avail this service through Temple of Miracles social media{" "}
          <br />
          accounts? ( Facebook and Instagram)
          <strong className="start"> *</strong>{" "}
        </h3>
        <div className="row">
          <div className="col-5"></div>
        </div>
        <div className="row radio">
          <div className="numero_option temple">
            <input
              type="radio"
              id="yes"
              name="social_media"
              value="yes"
              ref={props.register({ required: true })}
              defaultChecked={props.formValues.social_media === "yes"}
              disabled={!!props.formValues.social_media}
            />
            &nbsp;&nbsp;
            <label for="yes">Yes</label>
          </div>
          <div className="numero_option temple">
            <input
              type="radio"
              id="no"
              name="social_media"
              value="no"
              ref={props.register({ required: true })}
              defaultChecked={props.formValues.social_media === "no"}
              disabled={!!props.formValues.social_media}
            />
            &nbsp;&nbsp;
            <label for="no">No</label>
          </div>
        </div>
        <br />
        <br />
        {props.errors.social_media && (
          <p className="error-temple">
            Did you avail this service through Temple of Miracles social <br />
            media accounts? ( Facebook and Instagram) is required.
          </p>
        )}
      </fieldset>

      <div className="temple-datepicker">
        <h3 className="socialmedia-temple">
          Candle Service Date<strong className="start-temple"> *</strong>{" "}
        </h3>

        <input
          type="date"
          className="temple-datepicker"
          name="date"
          defaultValue={props.formValues.date || ""}
          ref={props.register({ required: true })}
          disabled={!!props.formValues.date}
        />
        {props.errors.date && (
          <p className="error-temple">This field is required</p>
        )}
      </div>
      <br />
    </React.Fragment>
  );
}

export default DatePicker;
