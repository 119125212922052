import React, { useState, useEffect } from "react";

function Information(props) {
  return (
    <fieldset>
      <legend className="heading-temple hfont-temple">
        Contact Information
      </legend>
      <div className="row">
        <div className="col-10 col-md-5">
          <h3 className="points-temple">
            <strong>Name</strong>
            <strong className="start-temple"> *</strong>
          </h3>
          <input
            className="input-temple"
            name="first"
            placeholder="First"
            defaultValue={props.formValues.first || ""}
            ref={props.register({ required: true })}
            disabled={!!props.formValues.first}
          />
          {props.errors.first && (
            <p className="error-temple">First are required.</p>
          )}
          <p6 className="in-temple inputline-temple">
            <em className="inputinfo-temple">
              Please enter the name of the person requesting the candle service
            </em>
          </p6>
        </div>
        <div className="col-1"></div>
        <div className="col-10 col-sm-10 col-md-5 margin-top-20">
          <h3 className="points-temple">
            <strong></strong>
            <strong className="start-temple"> </strong>
          </h3>
          <input
            className="input-temple"
            name="last"
            placeholder="Last"
            defaultValue={props.formValues.last || ""}
            ref={props.register({ required: true })}
            disabled={!!props.formValues.last}
          />
          {props.errors.last && (
            <p className="error-temple">Last are required.</p>
          )}
        </div>
      </div>

      <div className="row">
        <div className="col-10 col-md-5">
          <h3 className="points-temple">
            <strong>Phone</strong>
            <strong className="start-temple"> *</strong>
          </h3>
          <input
            className="input-temple"
            name="phone"
            defaultValue={props.formValues.phone || ""}
            ref={props.register({
              required: true,
              minLength: 10,
              maxLength: 10,
            })}
            disabled={!!props.formValues.phone}
          />
          {props.errors.phone && (
            <p className="error-temple">Phone is required.</p>
          )}
        </div>
        <div className="col-1"></div>
        <div className="col-10 col-md-5">
          <h3 className="points-temple">
            <strong>Email</strong>
            <strong className="start-temple"> *</strong>
          </h3>
          <input
            className="input-temple"
            name="email"
            defaultValue={props.formValues.email || ""}
            ref={props.register({
              required: true,
              type: "email",
              pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            })}
            disabled={!!props.formValues.email}
          />
          {props.errors.email && (
            <p className="error-temple">Email is required.</p>
          )}
          <p6 className="in-temple inputline-temple">
            <em className="inputinfo-temple">
              Notifications with respect to this request would be sent to this
              mail id
            </em>
          </p6>
        </div>
      </div>
    </fieldset>
  );
}

export default Information;
