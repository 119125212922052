import React, { useState, useEffect } from "react";

function Image() {
  return (
    <React.Fragment>
      <img className="img-temple" src="/blackcandleservice.jpg"></img>
      <div className="textdiv-temple col-12">
        <h2 className="heading-temple">Black Candle Service</h2>
        <p6 className="pera-temple">
          At Temple of Miracles Candle service is performed on all days to
          honour Papa and to pray for your wishes. Please choose the black
          candles that you would like to be lit for you and your loved ones to
          banish the negativities from their lives. Monday's is Papa Elegua's
          day and a special Candle Service is performed on all Mondays.
        </p6>
      </div>
      <br />
      <div className="textdiv-temple col-12">
        <p6 className="important-temple">
          <strong>IMPORTANT:</strong>
          <br />
        </p6>
        <p6 className="points-temple">
          <strong>
            1. For lighting candles for wishes please raise a request
            through&nbsp;
          </strong>
          <a
            className=" link-temple"
            href="https://www.cognitoforms.com/TempleOfMiracles2/CandleService"
          >
            https://www.cognitoforms.com /TempleOfMiracles2/ CandleService
          </a>
        </p6>
        <br />
        <p6 className="points-temple">
          <strong>
            2. All payments should go to the sheelambajaj instamojo account.
          </strong>
        </p6>
        <br />
        <p6 className="points-temple">
          <strong>3. For international payments please use paypal to </strong>
          <a
            className="link-temple"
            href="mailto:sheelabajaj@gmail.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            sheelabajaj@gmail.com
          </a>
          <strong>
            . ATTACH the payment screenshot with ths form and SUBMIT the form.
          </strong>
        </p6>
        <br />
        <p6 className="points-temple">
          <strong>
            4. For non-international payments, you would be redirected to the
            payment gateway on submission of the form.&nbsp;YOU WOULD ALSO
            RECEIVE THE PAYMENT LINK VIA EMAIL WHICH CAN BE USED IN CASE YOU
            WANT TO RETRY THE PAYMENT.
          </strong>
        </p6>
        <br />
      </div>
      <br />
    </React.Fragment>
  );
}

export default Image;
