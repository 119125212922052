import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import "./fived.css";
import axios from "axios";
import DayPicker, { DateUtils } from "react-day-picker";
import "react-day-picker/lib/style.css";
import {
  urlParamParser,
  checkPaymentStatus,
  getPaymentData,
  clearlocalStorage,
} from "../lib/helper";

const allowedCodes = ["sm11", "sn22","vy11"];

const qs = require("querystring");

function FiveDManual() {
  const { register, handleSubmit, watch, errors, reset,setError } = useForm();
  const [formStatus, setFormStatus] = useState("STAGED");
  const [qty, setQty] = useState(parseInt(1));
  const [selectedDays, setSelectedDays] = useState([]);

  let arr = Array.from(Array(qty).keys());

  useEffect(() => {
    arr = Array.from(Array(qty).keys());
  }, [qty]);

  const handleDayClick = (day, modifiers = {}, { selected }) => {
    if (modifiers.disabled) {
      return;
    }
    const localDays = [...selectedDays];
    if (selected) {
      const selectedIndex = localDays.findIndex((selectedDay) =>
        DateUtils.isSameDay(selectedDay, day)
      );
      localDays.splice(selectedIndex, 1);
    } else {
      localDays.push(day);
    }
    if (localDays.length > 11) {
      alert("At max 11 sessions are allowed");
      return;
    } else {
      setSelectedDays(localDays);
    }
  };

  const onSubmit = async (data, e) => {
    if (allowedCodes.indexOf(data.code) > -1) {
      if (selectedDays.length === 0) {
        setError("datepicker", {
          type: "manual",
          message: "Date Selection is mandatory",
        });
        return;
      }
      setFormStatus("INPROGRESS");
      const finalData = {
        ...data,
        dates: JSON.stringify(selectedDays),
        comments: `Manually Added by ${data.code}`,
      };
      const config = {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      };
      try {
        const response = await axios.post(
          "https://script.google.com/macros/s/AKfycbxK94DgCb5WKmWWcfsax82rjIdToAHjGMjeXS95xvqeH33ktgU/exec",
          qs.stringify(finalData),
          config
        );
        if (response.status == 200) {
          alert("Added");
          window.history.pushState({}, document.title, "/sheelaa/5dmanual");
          reset();
          setFormStatus("STAGED");
          setSelectedDays([]);
        } else {
          alert("Faield, try submitting again !!!");
        }
      } catch (err) {
        alert("Error Came " + err.message);
        setFormStatus("STAGED");
      }
    } else {
      alert("You are not authorised");
    }
  };

  // console.log(watch("example"));
  return (
    <React.Fragment>
      <h3>
        5th Dimensional Manual healing by Sheelaa M Bajaj <br />
        <span>Registration form</span>{" "}
      </h3>
      <form onSubmit={handleSubmit(onSubmit)}>
        <label>Enter Total Person</label>
        <input
          onChange={(e) => {
            let val = e.target.value < 1 ? 1 : e.target.value;
            if (parseInt(val) > 6) {
              val = 6;
            }
            setQty(parseInt(val));
          }}
          type="number"
          defaultValue={qty}
          name="quantity"
          ref={register({ required: true })}
        />
        {arr.map((q, index) => {
          return (
            <React.Fragment>
              <label>{`Enter Name ${index + 1}`}</label>
              <input
                defaultValue={""}
                name={`name${index}`}
                ref={register({ required: true })}
              />
              {errors[`name${index}`] && <p>This field is required</p>}
            </React.Fragment>
          );
        })}

        <label>Enter Secret Code</label>
        <input
          defaultValue={""}
          name={`code`}
          ref={register({ required: true })}
        />
        {errors[`code`] && <p>This field is required</p>}
        <label>Select Days(mandatory)</label>
        <div className="datepicker">
          <DayPicker
            selectedDays={selectedDays}
            onDayClick={handleDayClick}
          />
          {errors.datepicker && <p>This field is required</p>}
        </div>

        {formStatus === "STAGED" && (
          <button
            type="pay"
            className="paynow"
            onClick={handleSubmit(onSubmit)}
          >
            ADD
          </button>
        )}
        {formStatus === "INPROGRESS" && <div className="loader"></div>}

        {/* {checkPaymentStatus() && <span>Please hit submit button to record your entry </span>} */}

        {/* <label>Comments</label>
        <textarea name="comments" ref={register} disabled={!checkPaymentStatus()} /> */}
        {/* {formStatus === "STAGED" && <input type="submit" disabled={!checkPaymentStatus()} />} */}
      </form>
    </React.Fragment>
  );
}

export default FiveDManual;
