import React from "react";
import { BrowserRouter as Router, Switch, Route, useRouteMatch, Link } from "react-router-dom";
import Fived from "../Sheelaa/Forms/5d";
import Numero from "../Sheelaa/Forms/numerology";
import FiveDManual from "../Sheelaa/Forms/5dmanual";
import Tom from "../Sheelaa/Forms/tom";
import Temple from "./temple"

export default () => {
  let match = useRouteMatch();
  return (
    <Router>
      <Switch>
        <Route path={`${match.path}/5d`}>
          <Fived />
        </Route>
        <Route path={`${match.path}/5dmanual`}>
          <FiveDManual />
        </Route>
        <Route path={`${match.path}/tom`}>
          <Tom />
        </Route>
        <Route path={`${match.path}/numerology`}>
          <Numero />
        </Route>
        <Route path={`${match.path}/temple`}>
          <Temple />
        </Route>
      </Switch>
    </Router>
  );
};
