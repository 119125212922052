import React, { useState, useEffect } from "react";
import Candle from "./Candle";

function AddCandle(props) {
  const [changeCandle, setChangeCandle] = useState({});

  function AddCandles(e) {
    e.preventDefault();
    props.setCandle({
      personIndex: props.person.index,
      candleIndex: props.person.candles.length + 1,
      candleValue: "",
    });
  }

  useEffect(() => {
    props.setCandle({
      personIndex: props.person.index,
      candleIndex: changeCandle.index ? changeCandle.index : 1,
      candleValue: changeCandle.value ? changeCandle.value : "",
      action: changeCandle.action ? changeCandle.action : undefined,
    });
  }, [changeCandle]);

  return (
    <React.Fragment>
      {props.person.candles.map((candleitem, i) => {
        return (
          <Candle
            personIndex={props.personIndex}
            candleitem={candleitem}
            setCandle={props.setCandle}
            setChangeCandle={setChangeCandle}
            personname={props.person.index}
            index={i + 1}
            register={props.register}
            errors={props.errors}
            formValues={props.formValues}
          />
        );
      })}
      <div className="candleinfo-temple">
        {props.person.candles.length == 6 ? (
          ""
        ) : (
          <button
            className={
              !!props.formValues.email
                ? "btn-text-light  temple-disabled"
                : "btn-text-light addcandlebtn-temple"
            }
            onClick={(e) => {
              AddCandles(e);
            }}
            disabled={!!props.formValues.email}
          >
            <span
              class={
                !!props.formValues.email
                  ? "material-icons temple-disabled"
                  : "material-icons"
              }
            >
              add
            </span>
            Add Black Candle
          </button>
        )}
      </div>
    </React.Fragment>
  );
}
export default AddCandle;
