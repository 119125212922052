import React, { useState, useEffect } from "react";
import { useForm, useFieldArray } from "react-hook-form";
import Image from "./Forms/components/Image";
import Information from "./Forms/components/Information";
import DatePicker from "./Forms/components/DatePicker";
import AddPerson from "./Forms/components/AddPerson";
import Radio from "./Forms/components/Radio";
import "./Forms/components/temple.css";
import axios from "axios";
import {
  urlParamParser,
  checkPaymentStatus,
  getPaymentData,
  clearlocalStorage,
} from "./lib/helper";

function getLocalData() {
  const data = localStorage.getItem("_udata");
  if (data) {
    console.log(data);
    return JSON.parse(data);
  } else {
    return null;
  }
}

function setLocalData(data) {
  if (data) {
    localStorage.setItem("_udata", JSON.stringify(data));
  }
}

function App() {
  const qs = require("querystring");
  const {
    register,
    handleSubmit,
    watch,
    control,
    errors,
    reset,
    setError,
  } = useForm();
  const [formValues, setFormValue] = useState(getLocalData() || {});
  const [formStatus, setFormStatus] = useState("NOPROGRESS");
  console.log(getLocalData());
  const [persons, setPersons] = useState([
    {
      person: "",
      changed: false,
      index: 1,
      candles: [],
    },
  ]);
  const [person, setPerson] = useState({});
  const [candle, setCandle] = useState({});
  const [candles, setCandles] = useState([]);
  const [candlemoney, setCandleMoney] = useState(false);
  const [changePerson, setChangePerson] = useState({});

  urlParamParser();

  const onSubmit = async (data) => {
    data.totalprize = collectCandles(persons).length
      ? collectCandles(persons).length * 510
      : formValues.totalprize;
    data.allpersons = persons;
    data.candlearry = collectCandles(persons);
    setLocalData(data);
    setFormStatus("INPROGRESS");

    try {
      const url =
        "https://us-central1-next-cms-a9a2b.cloudfunctions.net/bot/temple/payment";
      data.token = "xyz1234";
      console.log(JSON.stringify(data));
      const res = await axios.post(url, data);
      localStorage.setItem("_preqid", res.data.id);
      setTimeout(() => window.location.replace(res.data.url, 2000));
    } catch (err) {
      alert(err);
      console.error(err);
      setFormStatus("NOPROGRESS");
    }
  };

  const performPostPaymentOperation = async (config) => {
    formValues.payment_id = localStorage.getItem("_preqid");
    console.log(formValues);
    try {
      const response = await axios.post(
        "https://script.google.com/macros/s/AKfycbzW9FlAB8mYf6j1QIn0CDVJNK3ezfvuVVbtjXL3U8w3M4Vm0ip7/exec",
        qs.stringify(formValues),
        config
      );
      if (response.status == 200) {
        clearlocalStorage();
        alert("Submitted, Please check your email");
        clearlocalStorage();
        setFormValue({});
        reset();
        setFormStatus("NOPROGRESS");
        window.history.pushState({}, document.title, "/sheelaa/temple");
      } else {
        alert("Faield, try submitting again !!!");
      }
    } catch (err) {
      alert("Error Came " + err.message);
      setFormStatus("NOPROGRESS");
    }
  };
  if (window.location.search && localStorage.getItem("_preqid")) {
    if (checkPaymentStatus()) {
      console.log("called");
      const config = {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      };
      performPostPaymentOperation(config);
      clearlocalStorage();
    } else {
      alert("something went wrong");
      clearlocalStorage();
      setFormValue({});
      window.history.pushState({}, document.title, "/sheelaa/temple");
    }
  }

  useEffect(() => {
    let temp = persons.map((item) => {
      if (changePerson.personindex + 1 === item.index) {
        item.person = changePerson.value;
        return item;
      } else {
        return item;
      }
    });
    setPerson(temp);
  }, [changePerson]);

  useEffect(() => {
    persons.forEach((item) => {
      if (item.index === candle.personIndex) {
        if (candle.action) {
          let temp = item.candles.map((cand) => {
            if (cand.candleIndex === candle.candleIndex) {
              return candle;
            } else {
              return cand;
            }
          });
          setCandles(temp);
        } else if (candle.value === null) {
          let temp = item.candles
            .map((cand) => {
              if (cand.candleIndex != candle.candleIndex) {
                if (cand.candleIndex > candle.candleIndex) {
                  return {
                    candleIndex: cand.candleIndex - 1,
                    personIndex: cand.personIndex,
                    candleValue: cand.candleValue,
                  };
                } else {
                  return cand;
                }
              }
            })
            .filter((item) => item);
          setCandles(temp);
        } else {
          setCandles([...item.candles, candle]);
        }
      }
    });
  }, [candle]);

  useEffect(() => {
    let temp = persons.map((item) => {
      if (item.index === candle.personIndex) {
        return {
          person: persons[candle.personIndex - 1].person,
          changed: candle.candleValue ? true : false,
          index: candle.personIndex,
          candles: candles,
        };
      } else {
        return item;
      }
    });
    setPersons(temp);
  }, [candles]);

  useEffect(() => {
    if (Object.keys(person).length && person.candles)
      setPersons([...persons, person]);
    if (!person.candles) {
      let temp = persons
        .map((item) => {
          if (item.index != person.index) {
            if (item.index > person.index) {
              return {
                person: item.person,
                index: item.index - 1,
                candles: item.candles,
              };
            } else {
              return item;
            }
          }
        })
        .filter((item) => item);
      setPersons(temp);
    }
  }, [person]);

  function collectCandles(persons) {
    return persons.reduce((acc, item) => {
      if (item.changed && !candlemoney) setCandleMoney(true);
      return [...acc, ...item.candles].filter((item) => {
        if (item.candleValue !== "") {
          return true;
        }
      });
    }, []);
  }

  return (
    <div className="container container-temple">
      <br />
      <Image />

      <form onSubmit={handleSubmit(onSubmit)} className="textdiv-temple col-12">
        <Information
          register={register}
          errors={errors}
          formValues={formValues}
        />

        <DatePicker
          register={register}
          errors={errors}
          formValues={formValues}
        />

        <div>
          <h3 className="heading-temple hfont-temple">
            Candle requests per person
          </h3>
          <p6 className="ine">
            <em className="inputinfo-temple">
              Click on Add Person for requesting candles for another person.
            </em>
          </p6>
        </div>

        <AddPerson
          setChangePerson={setChangePerson}
          persons={persons}
          setPersons={setPersons}
          setPerson={setPerson}
          setCandle={setCandle}
          register={register}
          errors={errors}
          formValues={formValues}
        />

        <div className="ml-2">
          <h3 className="heading-temple hfont-temple ">
            International Payment
          </h3>
          <h3 className="socialmedia-temple">
            Is this an international Payment (from outside India)?
            <strong className="start-temple"> *</strong>{" "}
          </h3>
          <div className="row">
            <div className="col-5"></div>
          </div>
          <Radio register={register} errors={errors} formValues={formValues} />
          {collectCandles(persons).length ? (
            <h3 className="heading-temple hfont-temple ">Payment</h3>
          ) : formValues.candlearry ? (
            <h3 className="heading-temple hfont-temple ">Payment</h3>
          ) : (
            ""
          )}
          {formValues.totalprize
            ? formValues.candlearry.map((item, index) => {
                return (
                  <React.Fragment>
                    <div className="">
                      <div className="colo-temple">
                        <h3 className="socialmedia-temple">
                          Black Candle{index + 1}
                        </h3>
                        <div className="socialmedia-temple">
                          <p6>
                            Candle - {item.candleValue}:
                            <span className="temple-candmoney">₹510</span>
                          </p6>
                        </div>
                      </div>
                    </div>
                  </React.Fragment>
                );
              })
            : collectCandles(persons).map((item, index) => {
                return (
                  <React.Fragment>
                    <div className="">
                      <div className="colo-temple">
                        <h3 className="socialmedia-temple">
                          Black Candle{index + 1}
                        </h3>
                        <div className="socialmedia-temple">
                          <p6>
                            Candle - {item.candleValue}:
                            <span className="temple-candmoney">₹510</span>
                          </p6>
                        </div>
                      </div>
                    </div>
                  </React.Fragment>
                );
              })}
          {collectCandles(persons).length ? (
            <div className=" colo-temple ">
              <strong>
                <p6 className="money-temple">
                  ₹{collectCandles(persons).length * 510}
                </p6>
              </strong>
              <p6 className="money-temple">
                <strong>Subtotal:</strong>
              </p6>
              <h5 className="temple-candmoney h6">
                Amount Due: ₹{collectCandles(persons).length * 510}
              </h5>
            </div>
          ) : formValues.candlearry ? (
            <div className=" colo-temple ">
              <strong>
                <p6 className="money-temple">₹{formValues.totalprize}</p6>
              </strong>
              <p6 className="money-temple">
                <strong>Subtotal:</strong>
              </p6>
              <h5 className="temple-candmoney h6">
                Amount Due: ₹{formValues.totalprize}
              </h5>
            </div>
          ) : (
            ""
          )}

          {formStatus == "INPROGRESS" ? (
            <div className="loading ml-5 "></div>
          ) : (
            <button className="btn addcandlebtn-temple" type="submit">
              Submit
            </button>
          )}
        </div>
        <input
          className="reset-temple"
          onClick={() => {
            clearlocalStorage();
            window.location.reload();
          }}
          type="Reset"
        />
      </form>
    </div>
  );
}

export default App;
