export const urlParamParser = () => {
  if (window.location.search) {
    const searchObj = new URLSearchParams(window.location.search);
    const paymentId = searchObj.get("payment_id");
    const paymentStatus = searchObj.get("payment_status");
    const paymentReqId = searchObj.get("payment_request_id");
    if (paymentId && paymentStatus && paymentReqId) {
      const paymentObj = { paymentId, paymentStatus, paymentReqId };
      localStorage.setItem("_pay", JSON.stringify(paymentObj));
    }
  }
};

export const checkPaymentStatus = () => {
  const pay = localStorage.getItem("_pay");
  const paymentReqId = localStorage.getItem("_preqid");

  if (pay) {
    const payData = JSON.parse(pay);
    if (payData.paymentStatus === "Credit" && paymentReqId == payData.paymentReqId) {
      return true;
    }
    return false;
  }
  return false;
};

export const clearlocalStorage = () => {
  localStorage.removeItem("_pay");
  localStorage.removeItem("_udata");
  localStorage.removeItem("_preqid");
  localStorage.removeItem("_tom");
  localStorage.removeItem("_retryurl");
  localStorage.removeItem("_uNdata"); 
  
};

export const getPaymentData = () => JSON.parse(localStorage.getItem("_pay"));
