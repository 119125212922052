import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import "./fived.css";
import axios from "axios";
import {
  urlParamParser,
  checkPaymentStatus,
  getPaymentData,
  clearlocalStorage,
} from "../lib/helper";

const qs = require("querystring");

function getLocalData() {
  const data = localStorage.getItem("_uNdata");
  if (data) {
    return JSON.parse(data);
  } else {
    return null;
  }
}
function setLocalData(data) {
  if (data) {
    localStorage.setItem("_uNdata", JSON.stringify(data));
  }
}

const num_options = {
  CRWD: `COMPREHENSIVE REPORT WITH DISCUSSION includes Numerology report, Element Chart report and phone consultation with Sheelaa M Bajaj

    The consultation fees for this Numerological service is Rs. 15,000 per person, either could be a baby name, child's name or adult name, includes
    Discussion with celebrity numerologist Sheelaa M Bajaj  on Skype for 5-10 mins after the report is received.
    Hand written name correction report with specific remedies and suggestions.
    Element analysis to increase luck factor in one's life by adding the missing element 
    Detailed report on numerological aspects influencing your life based on your date of birth and name will be prepared and shared.
    Lucky numbers, dates, colors, compatibility, line of work and other tips to increase one's luck and remove obstacles is part of the report. This is the uniqueness of Sheelaa M Bajaj, as she uses a multi disciplinary, multi dimensional approach to solve an issue and improve the overall luck factor.`,
  NCRWD: `NAME CORRECTION REPORT WITH  DISCUSSION includes numerology report and phone consultation with Sheelaa M Bajaj

    The consultation fees for this Numerological service is Rs. 10,000 per person, either could be a baby name, child's name or adult name, includes
    Discussion  with celebrity numerologist Sheelaa M Bajaj  on Skype for 5-10 mins after the report is received
    Hand written name correction report with specific remedies and suggestions
    Detailed report on numerological aspects influencing your life based on your date of birth and name will be  prepared and shared.`,
  CRWOD: `NAME CORRECTION REPORT WITH  DISCUSSION includes numerology report and phone consultation with Sheelaa M Bajaj

    The consultation fees for this Numerological service is Rs. 10,000 per person, either could be a baby name, child's name or adult name, includes
    Discussion  with celebrity numerologist Sheelaa M Bajaj  on Skype for 5-10 mins after the report is received
    Hand written name correction report with specific remedies and suggestions
    Detailed report on numerological aspects influencing your life based on your date of birth and name will be  prepared and shared.`,
  NCRWOD: `NAME CORRECTION REPORT WITHOUT CONSULTATION includes numerology report only. Excludes element report and phone consultation with Sheelaa M Bajaj

    This option for who are looking for just a name correction with numerology report and not keen to get a element report and not consult with Sheelaa M Bajaj. This service is priced at Rs. 5,000 per person. 
    Hand written name correction report with specific remedies and suggestions is provided.
    Detailed report on numerological aspects influencing your life based on your date of birth and name will be prepared and shared.`,
};

export default () => {
  const { register, handleSubmit, watch, errors, reset } = useForm();
  const [formValues, setFormValue] = useState(getLocalData() || {});
  const [showPopup, setPopupData] = useState(null);

  const [formStatus, setFormStatus] = useState("STAGED");
  urlParamParser();

  useEffect(() => {
    if (checkPaymentStatus()) {
      onSubmit({ ...formValues, ...getPaymentData() });
    }
  }, [formValues]);

  const onSubmit = async (data, e) => {
    setFormStatus("INPROGRESS");
    const finalData = {
      ...data,
      ...formValues,
    };
    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };
    try {
      const response = await axios.post(
        "https://script.google.com/macros/s/AKfycbzAP8wHQSo-31cJxPVCIj7DidblkS_e-Nvfpi9q1lHF_R6qAWxY/exec",
        qs.stringify(finalData),
        config
      );
      if (response.status == 200) {
        clearlocalStorage();
        alert("Submitted, Please check your email");
        window.history.pushState({}, document.title, "/sheelaa/numerology");
        clearlocalStorage();
        setFormValue({});
        // e.target.reset();
        reset();
        setFormStatus("STAGED");
      } else {
        alert("Faield, try submitting again !!!");
      }
    } catch (err) {
      alert("Error Came " + err.message);
      setFormStatus("STAGED");
    }
  };

  const onPay = async (data) => {
    setLocalData(data);
    if (data.payOption === "payLater") {
      return onSubmit(data);
    }
    setFormStatus("INPROGRESS");

    try {
      const url =
        "https://us-central1-next-cms-a9a2b.cloudfunctions.net/bot/numero";
      data.token = "xyz123";
      const res = await axios.post(url, data);
      localStorage.setItem("_preqid", res.data.id);
      //   window.history.pushState({}, document.title, res.data.url);
      setTimeout(() => window.location.replace(res.data.url, 2000));
      setFormStatus("STAGED");
    } catch (err) {
      console.error(err);
      setFormStatus("STAGED");
    }
  };

  const handleKnowMore = (val) => {
    const data = num_options[val];
    setPopupData(data);
  };

  return (
    <React.Fragment>
      <h3>Numerology By Sheelaa M Bajaj</h3>
      {formStatus === "INPROGRESS" && <div className="loader"></div>}
      <hr />
      {showPopup !== null && (
        <div id="open-modal" class="modal-window">
          <div>
            <span onClick={() => setPopupData(null)} class="modal-close">
              Close
            </span>
            <h1>Description</h1>
            <div>{showPopup}</div>
          </div>
        </div>
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        <span className="ntitle">Select Numerology Option</span>
        <div className="numero_wrap">
          <div className="numero_option">
            <input
              type="radio"
              id="CRWD"
              name="option"
              value="CRWD"
              ref={register({ required: true })}
              defaultChecked={
                formValues.option === "CRWD" ||
                (formValues.option === undefined && true)
              }
              disabled={!!formValues.option}
            />
            <label for="CRWD">Name Correction report Rs 7500</label>
            {/* <span className="knw_more" onClick={() => handleKnowMore("CRWD")}>
              Know More
            </span> */}
          </div>
          <div className="numero_option">
            <input
              type="radio"
              id="NCRWD"
              name="option"
              value="NCRWD"
              ref={register({ required: true })}
              defaultChecked={formValues.option === "NCRWD"}
              disabled={!!formValues.option}
            />
            <label for="NCRWD">Name Correction Report and Element report Rs 15999</label>
            {/* <span className="knw_more" onClick={() => handleKnowMore("NCRWD")}>
              Know More
            </span> */}
          </div>
          <div className="numero_option">
            <input
              type="radio"
              id="CRWOD"
              name="option"
              value="CRWOD"
              ref={register({ required: true })}
              defaultChecked={formValues.option === "CRWOD"}
              disabled={!!formValues.option}
            />
            <label for="CRWOD">Name correction + Element chart + SMB consult Rs 19999</label>
            {/* <span className="knw_more" onClick={() => handleKnowMore("CRWOD")}>
              Know More
            </span> */}
          </div>
        </div>

        <label>Enter First Name</label>
        <input
          defaultValue={formValues.name || ""}
          name={`name`}
          ref={register({ required: true })}
          disabled={!!formValues.name}
        />
        {errors[`name`] && <p>This field is required</p>}

        <label>Enter Middle Name</label>
        <input
          defaultValue={formValues.middle_name || ""}
          name={`middle_name`}
          ref={register()}
          disabled={!!formValues.middle_name}
        />

        <label>Enter Last Name</label>
        <input
          defaultValue={formValues.last_name || ""}
          name={`last_name`}
          ref={register({ required: true })}
          disabled={!!formValues.last_name}
        />
        {errors[`last_name`] && <p>This field is required</p>}

        <label>Enter Nick Name</label>
        <input
          defaultValue={formValues.nick_name || ""}
          name={`nick_name`}
          ref={register()}
          disabled={!!formValues.nick_name}
        />
        {errors[`nick_name`] && <p>This field is required</p>}

        <label>Enter Date of Birth</label>
        <input
          type="date"
          defaultValue={formValues.dob || ""}
          name="dob"
          ref={register({ required: true })}
          disabled={!!formValues.dob}
        />
        {errors["dob"] && <p>This field is required</p>}
        <label>Enter Time of Birth(AM/PM)</label>
        <input
          type="time"
          defaultValue={formValues.time || ""}
          name="time"
          ref={register({ required: true })}
          disabled={!!formValues.time}
        />
        {errors["time"] && <p>This field is required</p>}
        <label>Enter Place Birth</label>
        <input
          type="text"
          defaultValue={formValues.place || ""}
          name="place"
          ref={register({ required: true })}
          disabled={!!formValues.place}
        />
        {errors["place"] && <p>This field is required</p>}
        <label>Enter Father Name</label>
        <input
          type="text"
          defaultValue={formValues.father_name || ""}
          name="father_name"
          ref={register({ required: true })}
          disabled={!!formValues.father_name}
        />
        {errors["father_name"] && <p>This field is required</p>}
        <label>Enter Mother Name</label>
        <input
          type="text"
          defaultValue={formValues.mother_name || ""}
          name="mother_name"
          ref={register({ required: true })}
          disabled={!!formValues.mother_name}
        />
        {errors["mother_name"] && <p>This field is required</p>}
        <label>Enter Husband/Wife Name</label>
        <input
          type="text"
          defaultValue={formValues.spouse_name || ""}
          name="spouse_name"
          ref={register({ required: true })}
          disabled={!!formValues.spouse_name}
        />
        {errors["spouse_name"] && <p>This field is required</p>}
        <label>Email</label>
        <input
          name="email"
          defaultValue={formValues.email || ""}
          ref={register({
            required: true,
            type: "email",
            pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
          })}
          disabled={!!formValues.email}
        />
        {errors.email && <p>This field is required</p>}
        <label>Mobile</label>
        <input
          name="phone"
          defaultValue={formValues.phone || ""}
          ref={register({ required: true })}
          disabled={!!formValues.phone}
        />
        {errors.phone && <p>Please enter mobile </p>}
        <label>Payment Option</label>
        <div className="numero_wrap">
          <div className="numero_option">
            <input
              type="radio"
              id="payNow"
              name="payOption"
              value="payNow"
              ref={register({ required: true })}
              defaultChecked={
                formValues.payOption === "payNow" ||
                (formValues.payOption === undefined && true)
              }
              disabled={!!formValues.payOption}
            />
            <label for="payNow">Pay Now</label>
          </div>
          <div className="numero_option">
            <input
              type="radio"
              id="payLater"
              name="payOption"
              value="payLater"
              ref={register({ required: true })}
              defaultChecked={formValues.payOption === "payLater"}
              disabled={!!formValues.payOption}
            />
            <label for="payLater">Pay Later</label>
          </div>
        </div>

        <label>Your Query (Problem with marriage, career etc)</label>
        <textarea name="query" ref={register()} disabled={!!formValues.query} />
        <h4>
          Payment Status:{" "}
          <span>{checkPaymentStatus() ? "Done" : "Not Done"}</span>
        </h4>
        {formStatus === "STAGED" && !checkPaymentStatus() && (
          <button type="pay" className="paynow" onClick={handleSubmit(onPay)}>
            Submit
          </button>
        )}
        {formStatus === "INPROGRESS" && <div className="loader"></div>}
      </form>
      <button
        type="pay"
        className="paynow reset"
        onClick={() => {
          clearlocalStorage();
          window.location.reload();
        }}
      >
        Reset
      </button>
    </React.Fragment>
  );
};
