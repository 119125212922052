import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import React from "react";
import SheelaaRoutes from "./pages/Sheelaa/routes";

export default function routes() {
  return (
    <Router>
      {/* A <Switch> looks through its children <Route>s and
              renders the first one that matches the current URL. */}
      <Switch>
        {/* <Route path="/about">
          <About />
        </Route> */}
        <Route path="/sheelaa">
          <SheelaaRoutes />
        </Route>
        <Route path="/">
          <Home />
        </Route>
      </Switch>
    </Router>
  );
}

function Home() {
  return <div style={{ color: "#fff" }}>Social Rect welcome you..</div>;
}
