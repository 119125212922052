import React, { useState, useEffect } from "react";
import AddCandle from "./AddCandle";

function AddPerson(props) {
  function addPerson(e, index) {
    e.preventDefault();
    props.setPerson({
      person: "",
      changed: false,
      index: props.persons.length + 1,
      candles: [],
    });
  }

  function Remove(e, index) {
    props.setPerson({ index: index });
  }

  function allPersons() {
    if (props.formValues.allpersons) {
      return props.formValues.allpersons;
    } else {
      return props.persons;
    }
  }

  return (
    <React.Fragment>
      {allPersons().map((person, personIndex) => {
        return (
          <React.Fragment>
            <div
              className={
                personIndex % 2 !== 0
                  ? "addperson-temple"
                  : "addpersonodd-temple"
              }
            >
              <div className=" row">
                <div className="col-3 person-temple">
                  <div
                    className="crossbutton-temple col-0"
                    onClick={(e) => {
                      props.formValues.email
                        ? console.log("")
                        : Remove(e, personIndex + 1);
                    }}
                  >
                    <span
                      class={
                        !!props.formValues.email
                          ? "material-icons temple-disabled"
                          : "material-icons"
                      }
                    >
                      highlight_off
                    </span>
                  </div>
                  <div className="crossbutton-temple col-2">
                    <h3 className="heading-temple coninfo-temple col-10">
                      Person{personIndex + 1}
                    </h3>
                  </div>
                </div>
              </div>
              <br />
              <div className="row">
                <div className="col-8 col-md-5 personnames-temple">
                  <h3 className="points-temple">
                    <strong>Name</strong>
                    <strong className="start-temple"> *</strong>
                  </h3>

                  <input
                    className="input-temple"
                    name={`person${personIndex + 1}`}
                    placeholder="This will get carved on the candle"
                    onChange={(e) => {
                      props.setChangePerson({
                        action: true,
                        value: e.target.value,
                        personindex: personIndex,
                      });
                    }}
                    value={
                      props.formValues[`person${personIndex + 1}`] ||
                      person.person
                    }
                    // defaultValue={
                    //   props.formValues[`person${personIndex + 1}`] || ""
                    // }
                    ref={props.register({ required: true })}
                    disabled={!!props.formValues[`person${personIndex + 1}`]}
                  />
                  {props.errors[`person${personIndex + 1}`] && (
                    <p className="error-temple">Name is required.</p>
                  )}
                </div>
              </div>

              <div className="candleinfo-temple">
                <h3 className="heading-temple coninfo-temple">
                  Details of Black Candles
                </h3>
                <p6 className="in-temple">
                  <em className="inputinfo-temple">
                    Payment for the Black candles to go to the sheelambajaj
                    instamojo account.
                  </em>
                </p6>
              </div>
              <AddCandle
                personIndex={personIndex}
                persons={person.candles}
                person={person}
                setCandle={props.setCandle}
                register={props.register}
                errors={props.errors}
                formValues={props.formValues}
              />
            </div>
          </React.Fragment>
        );
      })}
      {props.persons.length == 0 ? (
        <p className="error-temple">
          Please specify at least 1 Candle requests per person.
        </p>
      ) : (
        ""
      )}
      {props.persons.length == 6 ? (
        ""
      ) : (
        <div className="ml-2">
          <button
            className={
              !!props.formValues.email
                ? "btn-text-light temple-disabled"
                : "btn-text-light addcandlebtn-temple"
            }
            onClick={(e) => {
              addPerson(e);
            }}
            disabled={!!props.formValues.email}
          >
            <span
              class={
                !!props.formValues.email
                  ? "material-icons temple-disabled"
                  : "material-icons"
              }
            >
              add
            </span>
            Add Person
          </button>
        </div>
      )}
    </React.Fragment>
  );
}

export default AddPerson;
