import React, { useState, useEffect } from "react";

function Radio(props) {
  const [radiodiv, setRadioDiv] = useState("");

  useEffect(() => {}, [radiodiv]);

  return (
    <React.Fragment>
      <div className="row">
        <div className="numero_option temple">
          <input
            type="radio"
            onChange={(e) => {
              setRadioDiv(e.target.value);
            }}
            id="yes"
            name="internatinal_payment"
            value="yes"
            ref={props.register({ required: true })}
            defaultChecked={props.formValues.internatinal_payment === "yes"}
            disabled={!!props.formValues.internatinal_payment}
          />
          &nbsp;&nbsp;
          <label for="yes">Yes</label>
        </div>
        <div className="numero_option temple">
          <input
            type="radio"
            onChange={(e) => {
              setRadioDiv(e.target.value);
            }}
            id="no"
            name="internatinal_payment"
            value="no"
            ref={props.register({ required: true })}
            defaultChecked={props.formValues.internatinal_payment === "no"}
            disabled={!!props.formValues.internatinal_payment}
          />
          &nbsp;&nbsp;
          <label for="no">No</label>
        </div>
      </div>
      {props.errors.internatinal_payment && (
        <p className="error-temple">This field is required.</p>
      )}

      {radiodiv === "yes" ? (
        <React.Fragment>
          <p6>
            <strong>Only indian payment accepted</strong>
            <br />
          </p6>
        </React.Fragment>
      ) : (
        <p6 className="h">
          Please sumit the form and you would be redirected to the payment
          gateway.
        </p6>
      )}
      <br />
    </React.Fragment>
  );
}

export default Radio;
