import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import "./fived.css";
import DayPicker, { DateUtils } from "react-day-picker";
import "react-day-picker/lib/style.css";
import axios from "axios";
import {
  urlParamParser,
  checkPaymentStatus,
  getPaymentData,
  clearlocalStorage,
} from "../lib/helper";

const qs = require("querystring");

function getLocalData() {
  const data = localStorage.getItem("_udata");
  if (data) {
    return JSON.parse(data);
  } else {
    return null;
  }
}

function setLocalData(data) {
  if (data) {
    localStorage.setItem("_udata", JSON.stringify(data));
  }
}

function FiveD() {
  const { register, handleSubmit, watch, errors, reset, setError } = useForm();
  const [formValues, setFormValue] = useState(getLocalData() || {});
  const [formStatus, setFormStatus] = useState("STAGED");
  const [qty, setQty] = useState(parseInt(formValues.quantity) || 1);

  const [selectedDays, setSelectedDays] = useState([]);

  const handleDayClick = (day, modifiers = {}, { selected }) => {
    if (modifiers.disabled) {
      return;
    }
    const localDays = [...selectedDays];
    if (localDays.length) {
      const selectedIndex = localDays.findIndex((selectedDay) =>
        DateUtils.isSameDay(selectedDay, day)
      );
      if (selectedIndex !== -1) {
        localDays.splice(selectedIndex, 1);
      } else {
        localDays.push(day);
      }
    } else {
      localDays.push(day);
    }
    if (localDays.length > 11) {
      alert("At max 11 sessions are allowed");
      return;
    } else {
      setSelectedDays(localDays);
    }
  };

  urlParamParser();

  let arr = Array.from(Array(qty).keys());

  useEffect(() => {
    if (checkPaymentStatus()) {
      clearlocalStorage();
      alert("Submitted, Please check your email");
      clearlocalStorage();
      setFormValue({});
      reset();
      setFormStatus("STAGED");
      window.history.pushState({}, document.title, "/sheelaa/5d");
    }
    arr = Array.from(Array(qty).keys());
  }, [formValues, qty]);

  const onPay = async (data) => {
    if (selectedDays.length === 0) {
      setError("datepicker", {
        type: "manual",
        message: "Date Selection is mandatory",
      });
      return;
    }
    data.dates = selectedDays;
    setLocalData(data);
    setFormStatus("INPROGRESS");

    try {
      const url =
        "https://us-central1-next-cms-a9a2b.cloudfunctions.net/bot/payment";
      data.token = "xyz123";
      console.log(data);
      const res = await axios.post(url, data);
      localStorage.setItem("_preqid", res.data.id);
      setTimeout(() => window.location.replace(res.data.url, 2000));
    } catch (err) {
      console.error(err);
    }
  };

  const enabledSpecialDays = [
    new Date(2020, 7, 3),
    new Date(2020, 7, 19),
    new Date(2020, 8, 2),
    new Date(2020, 8, 17),
    new Date(2020, 9, 2),
    new Date(2020, 9, 17),
    new Date(2020, 9, 31),
    new Date(2020, 10, 15),
    new Date(2020, 10, 30),
    new Date(2020, 11, 14),
    new Date(2020, 11, 30),
  ];

  function checkDisabledDays(day) {
    return false;  // Enabling all days
    const dayz = day.getDay();
    const enabledDays = [1, 5, 6];
    if(DateUtils.isDayBetween(day,new Date(2020,6,28  ),new Date(2020,7,14))){
      return false;
    }
    const isMoonDay = () =>
      enabledSpecialDays.filter((d) => DateUtils.isSameDay(d, day));
    if (enabledDays.indexOf(dayz) > -1 || isMoonDay().length > 0) {
      if (
        DateUtils.isDayAfter(day, new Date()) ||
        DateUtils.isSameDay(day, new Date())
      ) {
        return false;
      }
      return true;
    }
    return true;
  }

  const moonDayStyle = `.DayPicker-Day--highlighted {
    background-color: orange;
    color: white;
  }`;

  const modifiers = {
    highlighted: (date, ...rest) => {
      return DateUtils.isSameDay(new Date(), date) > 0 ? date : null;
    },
  };
  // console.log(watch("example"));
  return (
    <React.Fragment>
      <h3>
        5th Dimensional healing by Sheelaa M Bajaj <br />
        <span>Registration form</span>{" "}
      </h3>
      <form>
        <label>Enter Total Person</label>
        <input
          onChange={(e) => {
            let val = e.target.value < 1 ? 1 : e.target.value;
            if (parseInt(val) > 6) {
              val = 6;
            }
            setQty(parseInt(val));
          }}
          type="number"
          defaultValue={formValues.quantity || qty}
          name="quantity"
          ref={register({ required: true })}
          disabled={!!formValues.quantity}
        />
        {arr.map((q, index) => {
          return (
            <React.Fragment>
              <label>{`Enter Name ${index + 1}`}</label>
              <input
                defaultValue={formValues[`name${index}`] || ""}
                name={`name${index}`}
                ref={register({ required: true })}
                disabled={!!formValues[`name${index}`]}
              />
              {errors[`name${index}`] && <p>This field is required</p>}
            </React.Fragment>
          );
        })}

        <label>Select Days(mandatory)</label>
        <div className="datepicker">
          <style>{moonDayStyle}</style>
          <DayPicker
            selectedDays={selectedDays}
            onDayClick={handleDayClick}
            disabledDays={checkDisabledDays}
          />
          {errors.datepicker && <p>This field is required</p>}
        </div>

        <label>Email</label>
        <input
          name="email"
          defaultValue={formValues.email || ""}
          ref={register({
            required: true,
            type: "email",
            pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
          })}
          disabled={!!formValues.email}
        />
        {errors.email && <p>This field is required</p>}
        <label>Mobile</label>
        <input
          name="phone"
          defaultValue={formValues.phone || ""}
          ref={register({ required: true, maxLength: 10 })}
          disabled={!!formValues.phone}
        />
        {errors.phone && <p>Please enter mobile 10 digit</p>}

        {formStatus === "STAGED" && !checkPaymentStatus() && (
          <button type="pay" className="paynow" onClick={handleSubmit(onPay)}>
            Pay Now & SUBMIT
          </button>
        )}
        {formStatus === "INPROGRESS" && <div className="loader"></div>}
        <h4>
          Payment Status:{" "}
          <span>{checkPaymentStatus() ? "Done" : "Not Done"}</span>
        </h4>

        {/* {checkPaymentStatus() && <span>Please hit submit button to record your entry </span>} */}

        {/* <label>Comments</label>
        <textarea name="comments" ref={register} disabled={!checkPaymentStatus()} /> */}
        {/* {formStatus === "STAGED" && <input type="submit" disabled={!checkPaymentStatus()} />} */}
      </form>
      <button
        type="pay"
        className="paynow reset"
        onClick={() => {
          clearlocalStorage();
          window.location.reload();
        }}
      >
        Reset
      </button>
    </React.Fragment>
  );
}

export default FiveD;
